import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End


interface S {
  // Customizable Area Start
  subscriptionEndDate: string;
  planName: string;
  isCancelModelOpen: boolean;
  isSuccessCancelModalOpen: boolean;
  isSubscriptionChangedModalOpen: boolean;
  isSubscriptionSuccessModalOpen: boolean;
  plans: any;
  selectedPlan: any;
  amount: any;
  storage:string;
  storageUnit:string;
  benefits: any;
  planAmount: any,
  interval: string,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ManageSubscriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserSubscriptionDetailId: string = '';
  cancelSubscriptionId: string = '';
  getPlansId: string = '';
  getPlansBenefitsId: string = '';
  changePlansId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      subscriptionEndDate: '',
      planName: '',
      isCancelModelOpen: false,
      isSuccessCancelModalOpen: false,
      isSubscriptionChangedModalOpen: false,
      isSubscriptionSuccessModalOpen: false,
      amount: '',
      storage:'',
      storageUnit:'',
      plans: [],
      selectedPlan: null,
      benefits: [],
      planAmount: '',
      interval: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson) {
      if (apiRequestCallId === this.getUserSubscriptionDetailId) {
        if (responseJson.subscriptions_history && responseJson.subscriptions_history.length > 0) {
          const subscriptionHistory = responseJson.subscriptions_history[0];
          const currentSubscriptionData = responseJson.current_subscription;
          this.setState({
            planAmount: currentSubscriptionData.amount,
            storage:currentSubscriptionData.storage,
            storageUnit:currentSubscriptionData.storage_unit,
            interval: currentSubscriptionData.interval,
            subscriptionEndDate: subscriptionHistory.end_date,
            planName: subscriptionHistory.plan_name,
          }, () => {
            this.getPlansBenefits();
          });
        }
      } else if (apiRequestCallId === this.getPlansId) {
        this.setState({ plans: responseJson });
      } else if (apiRequestCallId === this.changePlansId) {
        this.getUserSubscriptionDetail()
        this.setState({ amount: this.state.selectedPlan.amount, planAmount: this.state.selectedPlan.amount });
      } else if (apiRequestCallId === this.getPlansBenefitsId) {
        this.setState({ benefits: responseJson.plan_benefits });
      } else if (apiRequestCallId === this.cancelSubscriptionId) {
        this.getUserSubscriptionDetail()
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start  
  async componentDidMount() {
    this.getUserSubscriptionDetail();
    this.getPlansDetail();
  }

  handleBoxClick = (plan: any) => {
    this.setState({ selectedPlan: plan });
  }

  handleCellClick = (color: any) => {
    switch (color) {
      case '#8833FF':
        this.props.navigation.navigate("PhotosStorage");
        break;
      case '#F59E0B':
        this.props.navigation.navigate("VideoStorage");
        break;
      case '#60A5FA':
        this.props.navigation.navigate("RecipesStorage");
        break;
      default:
        this.props.navigation.navigate("ManageSubscription");
    }
  };

  handleCancelModal = () => {
    this.setState({ isCancelModelOpen: true })
  }

  handleCloseCancelModal = () => {
    this.setState({ isCancelModelOpen: false })
  }

  handleCancelSuccessModal = () => {
    this.cancelSubscription()
    this.setState({ isCancelModelOpen: false, isSuccessCancelModalOpen: true })
  }

  handleCloseCancelSuccessModal = () => {
    this.setState({ isSuccessCancelModalOpen: false })
  }

  handleChangeModal = () => {
    this.setState({ isSubscriptionChangedModalOpen: true })
  }

  handleCloseChangeModal = () => {
    this.setState({ isSubscriptionChangedModalOpen: false })
  }

  handleSuccessChangeModal = () => {
    const { selectedPlan } = this.state;
    if (selectedPlan) {
      this.changePlansDetail(selectedPlan);
      this.setState({ isSubscriptionChangedModalOpen: false, isSubscriptionSuccessModalOpen: true })
    }
  }

  handleSuccessCloseChangeModal = () => {
    this.setState({ isSubscriptionSuccessModalOpen: false })
  }

  handleSubscription = () => {
    this.props.navigation.navigate("Subscription");
  }

  getUserSubscriptionDetail = () => {
    let token = localStorage.getItem("token");
    const header = { token: token };
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUserSubscriptionDetailId = requestMsg.messageId;
    requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_subscriptionbilling/get_subscription`);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true
  }

  cancelSubscription = () => {
    let token = localStorage.getItem("token");
    const header = { token: token };
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.cancelSubscriptionId = requestMsg.messageId;
    requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_subscriptionbilling/cancel_subscription`)
    requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true
  }

  getPlansDetail = () => {
    let token = localStorage.getItem("token");
    const header = { token: token };
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getPlansId = requestMsg.messageId;
    requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_subscriptionbilling/plan_and_price`);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true
  }

  getPlansBenefits = () => {
    let token = localStorage.getItem("token");
    const header = { token: token };
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getPlansBenefitsId = requestMsg.messageId;
    requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_subscriptionbilling/subscription_plan/find_by_name?name=${this.state.planName}`);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true
  }

  changePlansDetail = (plan: any) => {
    let token = localStorage.getItem("token");
    const params = { plan: plan.product_name };
    const convertString = (params: any) => {
      return Object.keys(params)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');
    };
    const queryString = convertString(params);
    const header = { token: token };
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.changePlansId = requestMsg.messageId;
    requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_subscriptionbilling/update_subscription?${queryString}`);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.exampleAPiMethod);
    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true
  }

  // Customizable Area End
}