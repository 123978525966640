import React from "react";

import {
    Box,
    // Customizable Area Start
    Button,
    Typography
} from "@material-ui/core";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { styled } from "@material-ui/core/styles";
import { PieChart, Pie, Tooltip, Cell } from "recharts";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import StorageController, {
    Props,
} from "./StorageController.web";
import { BackArrowBlack, familyImg, group, home } from "./assets";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import HeaderDropdown from "../../navigationmenu/src/HeaderDropdown.web";
// Customizable Area End

// Customizable Area Start
interface DataItems {
    name: string;
    value: number;
    color: string;
}

const dataItem: DataItems[] = [
    { name: 'Videos', value: 50, color: '#F59E0B' },
    { name: 'Others', value: 50, color: '#5D6063' },
];

const usedData = dataItem.filter(item => item.name !== 'Free Space');
const totalUsed = usedData.reduce((acc, item) => acc + item.value, 0);

const renderCustomLabel = ({ cx, cy }: any) => {
    return (
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill="#333" className="middlepieTexts">
            {`${totalUsed} GB`}
        </text>
    );
};
// Customizable Area End

export default class VideoStorage extends StorageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <VideoStyle>
                <HeaderDropdown data-test-id="headerBoxDiv" navigation={this.props.navigation} id={""} />
                <Box style={{ padding: '30px', marginLeft: '50px', marginRight: '20px' }}>
                    <Breadcrumbs style={{ marginLeft: '70px' }} separator={<NavigateNextIcon fontSize="small" />}>
                        <Button className="breadcrumbsButtons" color="inherit" data-test-id="breadcrumbsButtons">
                            Settings
                        </Button>
                        <Button className="breadcrumbsButtons" color="inherit" data-test-id="breadcrumbsButtons" onClick={this.handleSubscription}>
                            Subscription
                        </Button>
                        <Button className="breadcrumbsButtons" color="inherit" data-test-id="breadcrumbsButtons" onClick={this.handleManageSubscription}>
                            Manage my subscription
                        </Button>
                        <Typography className="breadcrumbsText">
                            Videos
                        </Typography>
                    </Breadcrumbs>
                    <Box style={{ display: 'flex', marginTop: '15px', marginLeft: '1%' }}>
                        <Button className="backButton" data-test-id="backButton" onClick={this.handleManageSubscription}>
                            <img style={{ color: 'black' }} src={BackArrowBlack} alt="Back" />
                        </Button>
                        <Typography className="titleTypography">Videos</Typography>
                    </Box>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-around', gap: '40px' , marginBottom:'8%', alignItems: 'baseline'}}>
                    <Box style={{ width: '460px' }}>
                        <Typography className="contributersTitle">Contributors</Typography>
                        <Box style={{ marginTop: '31px' }}>
                            <Box style={{ display: 'flex' }}>
                                <Typography className="familyTItle"><img style={{ marginRight: '10px', color: '#5D6063' }} src={home} />Family</Typography>
                                <Typography className="storageTitle"> 30 GB used</Typography>
                            </Box>
                            <Box style={{ display: 'flex', gap: '60px' }}>
                                <Box className="boxDiv">
                                    <img src={familyImg} />
                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Box style={{ marginTop: '20px' }}>
                                            <Typography className="nameTitle">Sarah</Typography>
                                            <Typography className="gbTitleText">20 GB</Typography>
                                        </Box>
                                        <Button className="expandBottons"><KeyboardArrowRightIcon style={{ color: '#FFFFFF' }} /></Button>
                                    </Box>
                                </Box>
                                <Box className="boxDiv">
                                    <img src={familyImg} />
                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Box style={{ marginTop: '20px' }}>
                                            <Typography className="nameTitle">Peter</Typography>
                                            <Typography className="gbTitleText">10 GB</Typography>
                                        </Box>
                                        <Button className="expandBottons"><KeyboardArrowRightIcon style={{ color: '#FFFFFF' }} /></Button>
                                    </Box>
                                </Box>
                            </Box>
                            <Box style={{ display: 'flex', justifyContent: 'end' }}>
                                <Button className="viewMoreBtn">View more</Button>
                            </Box>
                        </Box>
                        <Box>
                            <Box style={{ display: 'flex' }}>
                                <Typography className="familyTItle"> <img style={{ color: '#5D6063', marginRight: '10px' }} src={group} /> Friends</Typography>
                                <Typography className="storageTitle"> 10 GB used</Typography>
                            </Box>
                            <Box style={{ display: 'flex', gap: '60px' }}>
                                <Box className="boxDiv">
                                    <img src={familyImg} />
                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Box style={{ marginTop: '20px' }}>
                                            <Typography className="nameTitle">Karla</Typography>
                                            <Typography className="gbTitleText">3 GB</Typography>
                                        </Box>
                                        <Button className="expandBottons"><KeyboardArrowRightIcon style={{ color: '#FFFFFF' }} /></Button>
                                    </Box>
                                </Box>
                                <Box className="boxDiv">
                                    <img src={familyImg} />
                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Box style={{ marginTop: '20px' }}>
                                            <Typography className="nameTitle">James</Typography>
                                            <Typography className="gbTitleText">7 GB</Typography>
                                        </Box>
                                        <Button className="expandBottons"><KeyboardArrowRightIcon style={{ color: '#FFFFFF' }} /></Button>
                                    </Box>
                                </Box>
                            </Box>
                            <Box style={{ display: 'flex', justifyContent: 'end' }}>
                                <Button className="viewMoreBtn">View more</Button>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="pieChartMainBox">
                        <Box className="individualsBoxs">
                            <Typography className="individualsTitle">Storage</Typography>
                            <Typography className="planTitle">Total storage in your plan:
                                <span style={{
                                    fontWeight: 700,
                                    fontFamily: "Poppins",
                                    fontSize: "16px",
                                    lineHeight: "24px",
                                    color: "#1C2110",
                                }}> 100 GB </span>
                            </Typography>
                        </Box>
                        <Box className="pieBox">
                            <PieChart width={320} height={280}>
                                <Tooltip />
                                <Pie
                                    data={dataItem}
                                    dataKey="value"
                                    outerRadius={100}
                                    innerRadius={75}
                                    labelLine={false}
                                    label={renderCustomLabel}
                                >
                                    {dataItem.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={entry.color} />
                                    ))}
                                </Pie>
                            </PieChart>
                            <Box>
                                {dataItem.map((entry, index) => (
                                    <Box key={`data-${index}`} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                        <Box style={{ width: '20px', height: '20px', borderRadius: '85px', backgroundColor: entry.color, marginRight: '10px' }} />
                                        <Typography>
                                            <span style={{
                                                fontWeight: 700,
                                                fontFamily: "Poppins",
                                                fontSize: "18px",
                                                lineHeight: "12px",
                                                color: "#1C2110",
                                            }}>
                                                {entry.name}</span>
                                            <span style={{
                                                fontWeight: 400,
                                                fontFamily: "Poppins",
                                                fontSize: "18px",
                                                lineHeight: "26px",
                                                color: "#1C2110",
                                            }}>: {entry.value} GB used </span></Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                        <Box style={{ padding: '30px' }}>
                            <Typography className="bottomText">The storage encompasses all personal images as well as those shared with contributors through photos or albums. This comprehensive space ensures a consolidated repository for both personal and collaborative visual content.</Typography>
                        </Box>
                    </Box>
                </Box>

            </VideoStyle >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const VideoStyle = styled("div")({
    "& .boxDiv": {
        width: '150px',
        boxShadow: "0 0 10px 5px rgba(0, 0, 0, 0.07)",
        padding: '30px',
        borderRadius: '8px',
        marginTop: '30px',
        display: 'flex',
        flexDirection: 'column'
    },
    "& .expandBottons": {
        borderRadius: '8px',
        backgroundColor: '#8CA74B',
        height: '24px'
    },
    "& .nameTitle": {
        fontWeight: 700,
        lineHeight: "22px",
        fontFamily: "Poppins",
        color: "#1C2110",
        fontSize: "14px"
    },
    "& .gbTitleText": {
        fontSize: "14px",
        fontWeight: 400,
        fontFamily: "Poppins",
        color: "#5D6063",
        marginTop: '5px',
        lineHeight: "22px"
    },
    "& .viewMoreBtn": {
        fontWeight: 700,
        fontFamily: "Poppins",
        fontSize: "14px",
        lineHeight: "22px",
        color: "#557C29",
        textTransform: 'none',
        marginTop: '15px',
        marginBottom: '20px'
    },
    "& .bottomText":{
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#1C2110",
    },
    "& .breadcrumbsButtons": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#57534E",
        "& .MuiButton-label": {
            textTransform: "none",
        },
    },
    "& .breadcrumbsText": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#1C2110",
    },
    "& .titleTypography": {
        fontWeight: 700,
        fontFamily: "Poppins",
        lineHeight: "40px",
        fontSize: "30px",
        color: "#1C2110",
        letterSpacing: "-0.5%",
    },
    "& .textBox": {
        display: 'flex'
    },
    "& .pieChartMainBox": {
        width: '620px',
        marginRight: '50px'
    },
    "& .benefitsBox": {
        width: '550px',
        marginRight: '15%',
        marginBottm: '10%'
    },
    "& .individualsBoxs":{
        marginBottom:'40px'
    },
    "& .planTitle": {
        fontFamily: "Poppins",
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: 400,
        color: "#1C2110",
    },
    "& .individualsTitle": {
        fontFamily: "Poppins",
        fontSize: "24px",
        lineHeight: "32px",
        color: "#1C2110",
        fontWeight: 700,
        marginBottom:'10px',
        letterSpacing: "-0.5%"
    },
    "& .pieBox": {
        alignItems: 'center',
        display: 'flex',
        marginLeft:'-45px',
        padding: '10px'
    },
    "& .middlepieTexts": {
        fontFamily: "Poppins",
        fontSize: "24px",
        lineHeight: "32px",
        letterSpacing: "-0.5%",
        fontWeight: 700,
        color: "#1C2110"
    },
    "& .contributersTitle": {
        fontWeight: 700,
        lineHeight: "32px",
        fontFamily: "Poppins",
        fontSize: "24px",
        letterSpacing: "-0.5%",
        color: "#1C2110"
    },
    "& .familyTItle": {
        fontWeight: 700,
        lineHeight: "26px",
        fontFamily: "Poppins",
        fontSize: "18px",
        color: "#5D6063"
    },
    "& .storageTitle": {
        fontFamily: "Poppins",
        fontWeight: 400,
        lineHeight: "24px",
        fontSize: "16px",
        marginLeft: '30px',
        color: "#5D6063"
    }

});
// Customizable Area End
