import React from 'react';
// Customizable Area Start
import {
    Button,
    styled,
    Box,
    IconButton,
    MenuItem,
    Menu,
    Divider,
    withStyles,
    MenuProps,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import PaymentIcon from '@material-ui/icons/Payment';
import HeaderDropdownController, {
    Props,
} from "./HeaderDropdownController";
import { Typography } from 'antd';
import { avatar, logo, subscription, logout } from '../../subscriptionbilling/src/assets';
// Customizable Area End

const StyledMenu = withStyles({
    paper: {
        borderRadius: '8px',
        border: '1px solid #D6D3D1',
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles(() => ({
    root: {
        color: '#1C2110',
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
    },
}))(MenuItem);

export default class HeaderDropdown extends HeaderDropdownController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const open = Boolean(this.state.anchorEl);
        return (
            // Customizable Area Start
            <HeaderStyle>
                <Box className="headerDiv">
                    <Box className="logoSection">
                        <img style={{ height: '44px', width: '32px' }} src={logo} alt="Logo" />
                    </Box>
                    <Box className="profileDiv">
                        <img src={avatar} alt="Avatar" />
                        <IconButton
                            aria-label="toggle-menu"
                            size="small"
                            onClick={this.handleClick}
                        >
                            {open ? <ExpandLessIcon fontSize="inherit" /> : <ExpandMoreIcon fontSize="inherit" />}
                        </IconButton>
                    </Box>

                    <StyledMenu
                        anchorEl={this.state.anchorEl}
                        open={open}
                        onClose={this.handleClose}
                    >
                        <Box style={{ display: 'flex', padding: '10px' }}>
                            <Box>
                                <img style={{ width: '56px', height: '56px' }} src={avatar} alt="Avatar" />
                            </Box>
                            <Box style={{ marginLeft: '13px' }}>
                                <Typography style={{
                                    fontFamily: 'Poppins',
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                }}>
                                    {this.state.fullName}
                                </Typography>
                                <Typography style={{
                                    marginTop: '3px',
                                    fontFamily: 'Poppins',
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                }}>
                                    {this.state.email}
                                </Typography>
                                <Button style={{
                                    backgroundColor: '#8CA74B',
                                    borderRadius: '8px',
                                    marginTop: '10px',
                                    fontFamily: 'Poppins',
                                    textTransform: 'none',
                                    fontSize: '14px',
                                    fontWeight: 700,
                                    color: '#FFFFFF',
                                    lineHeight: '22px',
                                    marginBottom: '5px',
                                }} onClick={this.editProfileClick}>
                                    Edit Profile
                                </Button>
                            </Box>
                        </Box>
                        <Divider />
                        <StyledMenuItem>
                            <Button style={{
                                color: '#78716C',
                                fontFamily: 'Poppins',
                                fontWeight: 400,
                                fontSize: '16px',
                                lineHeight: '24px',
                                textTransform: 'none',
                            }}>
                                SETTINGS
                            </Button>
                        </StyledMenuItem>
                        <StyledMenuItem>
                            <img src={subscription} alt="Subscription" />
                            <Button style={{
                                color: '#1C2110',
                                fontFamily: 'Poppins',
                                fontWeight: 400,
                                fontSize: '16px',
                                lineHeight: '24px',
                                textTransform: 'none',
                            }} onClick={this.subscriptionClick}>
                                My subscription
                            </Button>
                        </StyledMenuItem>
                        <StyledMenuItem>
                            <PaymentIcon />
                            <Button style={{
                                color: '#1C2110',
                                fontFamily: 'Poppins',
                                fontWeight: 400,
                                fontSize: '16px',
                                lineHeight: '24px',
                                textTransform: 'none',
                            }} onClick={this.paymentClick}>
                                Payments
                            </Button>
                        </StyledMenuItem>
                        <Divider />
                        <StyledMenuItem>
                            <img src={logout} alt="Logout" />
                            <Button style={{
                                color: '#1C2110',
                                fontFamily: 'Poppins',
                                fontWeight: 400,
                                fontSize: '16px',
                                lineHeight: '24px',
                                textTransform: 'none',
                            }} onClick={this.logoutBtn}>
                                Log out
                            </Button>
                        </StyledMenuItem>
                    </StyledMenu>
                </Box>
            </HeaderStyle>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const HeaderStyle = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    borderBottom: '1px solid #E7E5E4',
    flexGrow: 1,
    boxShadow: '0 0 10px 5px rgba(0, 0, 0, 0.07)',
    '& .headerDiv': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '20px',
        borderBottom: '1px solid #E7E5E4',
    },
    '& .profileDiv': {
        display: 'flex',
        alignItems: 'center',
    },
    '& .logoSection': {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '30px'
    },
});
// Customizable Area End