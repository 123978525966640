import React from "react";

import { Box, Button, Typography, Divider, IconButton } from "@material-ui/core";

// Customizable Area Start

// Customizable Area End

import SubscriptionbillingController, {
    Props,
} from "./SubscriptionbillingController";
import { failPayment } from "./assets";
import CloseIcon from '@mui/icons-material/Close';
import HeaderDropdown from "../../navigationmenu/src/HeaderDropdown.web";


export default class PaymentFailed extends SubscriptionbillingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.getBankSubscriptionApiCall();
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <Box>
                <Box style={{ backgroundColor: '#FEFFFB' }}>
                    <HeaderDropdown data-test-id="header" navigation={this.props.navigation} id={""} />
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100vh', 
                        }}
                    >
                        <Box
                            style={{
                                width:'100%',
                                maxWidth: 545,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                borderRadius: 8,
                                borderBottomRightRadius: 50,
                                boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.25)', 
                                backgroundColor: '#fff',
                                padding: 20,
                                position: 'relative',
                            }}
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    position: 'relative'
                                }}
                            >
                                <Typography style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins', color: '#1C2110' }}>
                                    Failed payment
                                </Typography>
                                <IconButton
                                    aria-label="close"
                                    onClick={this.handleCloseModal}
                                    style={{
                                        position: 'absolute',
                                        right: 8,
                                        top: -6,
                                        color: '#666',
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                            <Divider style={{
                              width: 'calc(91% + 90px)',
                              margin: '10px -20px',
                              marginTop: 20
                            }} />
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    padding: '20px 0',
                                }}
                            >
                                <div
                                    style={{
                                        width: "130px",
                                        height: "130px",
                                        borderRadius: "50%",
                                        backgroundColor: "#F87171",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <img
                                        src={failPayment}
                                        alt="Failed Payment"
                                        style={{ width: 85 }}
                                    />
                                </div>
                                <Typography style={{ textAlign: 'center', marginTop: 16, fontSize: 20, fontWeight: 700, fontFamily: 'Poppins', color: '#1C2110' }}>
                                    Failed to process your payment
                                </Typography>
                                <Typography style={{ textAlign: 'center', marginTop: 8, fontFamily: 'Poppins', fontWeight: 400, fontSize: 16, color: '#1C2110' }}>
                                    We were unable to process your payment. Please double-check your payment information and ensure that the details provided are accurate.
                                </Typography>
                            </Box>
                            <Divider style={{
                              width: 'calc(91% + 90px)',
                              margin: '10px -20px',
                              marginTop: 20
                            }} />
                            <Box style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <Button style={webStyle.continueButton2} onClick={this.handleTryAgain}>
                                    <Typography style={webStyle.continueText2 as any}>Cancel</Typography>
                                </Button>
                                <Button style={webStyle.continueButton} onClick={this.handleTryAgain}>
                                    <Typography style={webStyle.continueText as any}>Try again</Typography>
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>

            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    continueButton: {
        backgroundColor: "#8CA74B",
        borderRadius: '8px',
        height: '56px',
        width: '195px',
        marginTop: '32px',
    },
    continueText: {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FFFFFF",
        textTransform: 'none'
    },
    continueButton2: {
        backgroundColor: '#F8FFE7',
        borderRadius: '8px',
        height: '56px',
        width: '195px',
        marginTop: '32px',
    },
    continueText2: {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: '#8CA74B',
        textTransform: 'none'
    }
}
// Customizable Area End


