import React from "react";

import {
  Box,
  Button,
  Typography,
  IconButton,
  FormControlLabel,
  Checkbox,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles"
import Header from "../../../components/src/Header.web"
import Footer from "../../navigationmenu/src/Footer.web"
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
// Customizable Area End

import TermsAndConditionsController, {
  Props,
} from "./TermsAndConditionsController.web";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Alert } from "@material-ui/lab";

export default class TermsAndConditions extends TermsAndConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <TermsAndConditionStyle>
          <Header onLoginClick={this.loginClick} onRegisterClick={this.registerClick} getHeaderData={()=>{}}/>
          <Box className="MainContainer">
            <Box className="sideContainer">
              {this.state.termPolicyData.map((term: any, index: number) => (
                <li key={term.id}>
                  <Button className="sidebarBtn"
                    style={{
                      backgroundColor: this.state.activePolicyIndex === index ? "#A7C460" : "inherit",
                      color:
                        this.state.activePolicyIndex === index ? "#1C2110" : "white",
                    }}
                    onClick={() => this.handleSidebarItemClick(index)}
                  >
                    {term.id}. {term.title}
                  </Button>
                </li>
              ))}
            </Box>
            <Box className="mainContent">
              <Box className="titleDiv">
                <IconButton onClick={this.handleCancle} style={{padding:'0' , marginLeft:'5px', marginRight:'15px'}}>
                  <ArrowBackIcon style={{ color: 'black', height: '32px', width: '32px' , cursor:'pointer'}} />
                </IconButton>
                <Typography className="titleText">Privacy Policy and Terms and Conditions</Typography>
              </Box>
              {this.state.showError && (
                <Alert
                  className="alertBox"
                  severity="error"
                  icon={false}
                  style=
                  {{
                    marginTop: '32px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '85%',
                    marginLeft: '45px',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Typography className="alertText" style={{ flex: 1 }}>
                      You have to agree with Privacy Policy and Terms and Conditions to Sign up
                    </Typography>
                    <IconButton style={{ marginLeft: 'auto', color: '#DC2626' }} size="small" onClick={() => this.setState({ showError: false })}>
                      <CloseSharpIcon fontSize="inherit" />
                    </IconButton>
                  </div>
                </Alert>
              )}
              <Box className="policyDiv">
                {this.state.termPolicyData.map((term: any, index: number) => (
                  <div key={term.id} id={`policy-${index}`}>
                    <Typography className="policyTitleText">{term.id}. {term.title}</Typography>
                    <Typography className="policyDescText"><div dangerouslySetInnerHTML={{ __html: term.description }} /></Typography>
                  </div>
                ))}
              </Box>
              <Box className="agreeBox">
                <FormControlLabel
                  data-test-id='agreeBox'
                  control={
                    <Checkbox
                      checked={this.state.isChecked}
                      style={{ color: this.state.showError ? "#DC2626" : (this.state.isChecked ? "#8CA74B" : "#1C2110") }}
                      onChange={this.handleCheckbox}
                    />
                  }
                  label={
                    <Typography className="subscribeCheckBoxTitle" style={{ color: this.state.showError ? "#DC2626" : "#1C2110", cursor: 'default' }}>I agree with the Privacy Policy and Terms and Conditions.</Typography>
                  }
                />
              </Box>
              <Box className="actionBtns">
                <Button type="submit" className="cancleBtn" data-test-id="cancleBtn" variant="contained" onClick={this.handleCancle}>
                  Cancel
                </Button>
                <Button type="submit" className="agreeBtn" variant="contained" data-test-id="agreeBtn" onClick={this.handleAgreement} >
                  Agree
                </Button>
              </Box>
            </Box>
          </Box>
        </TermsAndConditionStyle>
        <Footer navigation={this.props.navigation} id={""} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const TermsAndConditionStyle = styled("div")({
  "& .MainContainer": {
    display: "flex",
  },
  "& .sideContainer": {
    backgroundColor: "#8CA74B",
    padding: "30px",
    height: '650px',
    borderBottomRightRadius: "64px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '@media (max-width: 1400px)': {
      padding: "20px",
      height: '650px',
    },
    '@media (max-width: 779px)': {
      padding: "10px",
      height: '750px',
    },
  },
  "& .sidebarBtn": {
    padding: "10px",
    borderRadius: '8px',
    marginTop: '8px',
    marginLeft:'5px',
    marginRight:'5px',
    width: '100%',
    "& .MuiButton-label": {
      textTransform: 'none',
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "16px",
    },
    '@media (max-width: 1400px)': {
      padding: "8px",
      fontSize: "14px",
    },
    '@media (max-width: 779px)': {
      padding: "4px",
      fontSize: "12px",
    },
  },
  "& .mainContent": {
    flex: 1,
    padding: "20px",
  },
  "& .titleDiv": {
    display: 'flex',
    alignItems: 'center'
  },
  "& .titleText": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "30px",
    color: "#1C2110",
    lineHeight: "40px",
    letterSpacing: "0.5%",
    '@media (max-width: 1400px)': {
      fontSize: "25px",
    },
    '@media (max-width: 810px)': {
      fontSize: "18px",
    },
    '@media (max-width: 999px)': {
      fontSize: "18px",
    },
  },
  "& .policyDiv": {
    padding: "20px",
    width: '85%',
    marginLeft: '45px'
  },
  "& .policyTitleText": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    color: '#1C2110',
    marginTop: '24px'
  },
  "& .alertBox": {
    borderRadius: '4px',
    border: '0px, 0px, 0px, 4px',
    borderLeft: '4px solid #DC2626',
    height: '48px',
    marginLeft: '50px',
    width: '85%',
    display: 'flex',
    alignItems: 'center',
    "& .MuiAlert-message": {
      width: '100%'
    }
  },
  "& .alertText": {
    fontSize: "12px",
    foneFamily: "Poppins",
    fontWeight: 400,
    lineHeight: '18px',
    color: '#DC2626'
  },
  "& .policyDescText": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: '#1C2110',
    marginTop: '7px'
  },
  "& .actionBtns": {
    gap: '16px',
    display: 'flex',
    padding: '20px',
    marginTop: '10px',
    marginBottom: '20px',
    marginLeft: '45px'
  },
  "& .cancleBtn": {
    border: '1px solid #C2E176',
    backgroundColor: '#F8FFE7',
    width: '200px',
    height: '56px',
    '& .MuiButton-label': {
      fontSize: "16px",
      fontWeight: 700,
      textTransform: "none",
      fontFamily: "Poppins",
      lineHeight: "24px",
      color: '#8CA74B'
    }
  },
  "& .agreeBtn": {
    backgroundColor: '#8CA74B',
    width: '200px',
    height: '56px',
    '& .MuiButton-label': {
      fontSize: "16px",
      fontWeight: 700,
      textTransform: "none",
      fontFamily: "Poppins",
      lineHeight: "24px",
      color: 'white'
    }
  },
  "& .agreeBox": {
    marginLeft: '65px'
  },
  "& .subscribeCheckBoxTitle": {
    color: '#1C2110',
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
  }
});
// Customizable Area End
