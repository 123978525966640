import React from "react";

import {
    Box,
    Button,
    Typography,
    InputAdornment,
    IconButton,
    TextField,
    FormControlLabel,
    Checkbox,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles"
import { backArrow, bgImage, mail } from "./assets";
import Header from "../../../components/src/Header.web";
import Footer from "../../navigationmenu/src/Footer.web";
// Customizable Area End

import NewsLetterController, {
    Props,
} from "./NewsLetterController";

export default class NewsLetter extends NewsLetterController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <Header data-test-id="header" onLoginClick={this.onLoginClick} onRegisterClick={this.onRegisterClick} getHeaderData={()=>{}}/>
                <NewsLetterStyle>
                    <Box className="mainContentDiv">
                        <Button data-test-id="backArraowClick" className="backButton" onClick={this.navigateBack}>
                            <img src={backArrow} alt="Back" />
                        </Button>
                        <Box style={{ marginLeft: '669px', marginBottom: '30px' }}>
                            <Typography className="newsletterTitle">NEWSLETTER</Typography>
                        </Box>
                        <Box className="mainContainer">
                            <Box className="innerBox">
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: '30px' }}>
                                    <img src={mail} style={{ height: '80px', width: '80px' }} />
                                </div>
                                <Typography className="mainTitle">Suscribe to our Newsletter</Typography>
                                <Typography className="subTitle">to be updated with all the latest trends and products.</Typography>
                                <TextField style={{ marginBottom: '15px' }}
                                    type="email"
                                    placeholder="Your e-mail"
                                    variant="outlined"
                                    required
                                    fullWidth />
                                <Button className="joinBtn" data-test-id="joinBtn" type="submit" onClick={this.subscribeNewsLetter}>Join</Button>
                            </Box>
                        </Box>
                    </Box>
                </NewsLetterStyle>
                <Footer navigation={this.props.navigation} id={""} />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const NewsLetterStyle = styled("div")({
    "& .mainContentDiv": {
        height: "100vh",
        width: '100%',
        backgroundImage: `url(${bgImage})`,
        backgroundColor: "#8CA74B",
        position: "relative",
        overflow: "hidden",
    },
    "& .backButton": {
        marginLeft: '60px',
        marginTop: '40px'
    },
    "& .mainContainer": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    "& .newsletterTitle": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "20px",
        lineHeight: "28px",
        color: "#FFFFFF"
    },
    "& .innerBox": {
        borderRadius: '16px',
        backgroundColor: "#FFFFFF",
        height: '400px',
        width: '490px',
        padding: '40px',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    "& .mainTitle": {
        fontFamily: "Poppins",
        fontWeight: 650,
        fontSize: "30px",
        lineHeight: "40px",
        color: "#1C2110",
        letterSpacing: '-0.5%',
        marginBottom: '9px'
    },
    "& .subTitle": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#1C2110",
        marginBottom: '20px'
    },
    "& .joinBtn": {
        backgroundColor: "#8CA74B",
        height: '56px',
        width: '280px',
        marginTop: '35px',
        borderRadius: '8px',
        '& .MuiButton-label': {
            fontFamily: "Poppins",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "24px",
            color: "#FFFFFF",
            textTransform: "none",
        },
    }
});
// Customizable Area End
