import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  email: string;
  error: string;
  success: string;
  apiError: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FooterController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  subscribeNewsLetteApiId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      email: '',
      error: '',
      success: '',
      apiError: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && apiRequestCallId === this.subscribeNewsLetteApiId) {
      if (responseJson?.errors && responseJson?.errors?.length !== 0) {
        const errorMessage = "Already subscribed to the newsletter";
        this.setState({
          email: '',
          apiError: errorMessage,
        })
      }
      else {
        this.subscribeNewsLetterCall()
        this.setState({ apiError: '' });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleHomeClick = () => {
    this.props.navigation.navigate("/");
  }

  handlePhotosClick = () => {
    this.props.navigation.navigate("PhotosPage");
  }

  handleMomentsClick = () => {
    this.props.navigation.navigate("MomentsPage");
  }

  handleFamilyClick = () => {
    this.props.navigation.navigate("FamilyTreePage");
  }

  handleRecipesClick = () => {
    this.props.navigation.navigate("RecipsPage");
  }

  handlePlansClick = () => {
    this.props.navigation.navigate("Subscriptionbilling");
  }

  handleContactClick = () => {
    this.props.navigation.navigate("ContactusPage");
  }

  handleBlogClick = () => {
    this.props.navigation.navigate("BlogpostsManagement");
  }

  handleNewsLetterClick = () => {
    console.log("dfd")
    this.props.navigation.navigate("NewsLetter");
  }

  handlePolicyClick = () => {
    this.props.navigation.navigate("TermsAndConditions");
  }

  handleSitemapClick = () => {
    this.props.navigation.navigate("Sitemap");
  }

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: event.target.value, error: '' });
  };

  emailClick = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!this.state.email) {
      this.setState({ error: 'Email cannot be empty.' });
    } else if (!emailRegex.test(this.state.email)) {
      this.setState({ error: 'Please enter a valid email address.' });
    } else {
      const sucessMessage = 'Successfully subscribe Newsletter!'
      this.setState({ error: '', success: sucessMessage });
    }
  };

  subscribeNewsLetterCall = () => {
    let token = localStorage.getItem("token");
    const header = { token: token };
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.subscribeNewsLetteApiId = requestMsg.messageId;
    requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `account_block/subscribe_to_newsletter?email=${this.state.email}`);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true
  }
  // Customizable Area End
}