import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string,
  email: string,
  contactNumber: string,
  subject: string,
  details: string,
  showSuccessSnackbar: boolean,
  showErrorSnackbar: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ContactusPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  contactDetailsApiId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      name: "",
      email: "",
      contactNumber: "",
      subject: "",
      details: "",
      showSuccessSnackbar: false,
      showErrorSnackbar: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson) {
      if (apiRequestCallId === this.contactDetailsApiId) {
        if (responseJson?.errors && responseJson?.errors?.length !== 0) {
          this.setState({
            showErrorSnackbar: true
          })
        } else {
          this.setState({
            name: '',
            email: '',
            contactNumber: '',
            subject: '',
            details: '',
            showSuccessSnackbar: true
          })
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  contactDetailSchema = Yup.object({
    name: Yup.string()
      .matches(/^[A-Za-z]+(?:\.| [A-Za-z]+)*(?: [A-Za-z]+)*(?: \.[A-Za-z]+)*\.?$/, 'Full name must contain only alphabets')
      .required("Please fill out the required field before proceeding."),
    email: Yup.string()
      .email("The email address you provided is not valid. Please enter a valid email address (example@example.com).")
      .required("Please fill out the required field before proceeding."),
    contactNumber: Yup.string()
      .required("Please fill out the required field before proceeding."),
    subject: Yup.string()
      .max(100, "Address cannot be longer than 100 characters")
      .matches(/^[^\s](.*[^\s])?$/, 'Subject cannot have leading or trailing spaces.')
      .required("Please fill out the required field before proceeding."),
    details: Yup.string()
      .max(500, "Address cannot be longer than 500 characters")
      .matches(/^[^\s](.*[^\s])?$/, 'Subject cannot have leading or trailing spaces.')
      .required("Please fill out the required field before proceeding."),
  });

  onLoginClick = () => {
    this.props.navigation.navigate("Login")
  }

  onRegisterClick = () => {
    this.props.navigation.navigate("Signuplogin")
  }

  submitContactDetails = (values: any) => {
    const details = {
      name: values.name,
      email: values.email,
      phone_number: values.contactNumber,
      subject: values.subject,
      details: values.details
    }

    this.setState({
      name: values.name,
      email: values.email,
      contactNumber: values.contactNumber,
      subject: values.subject,
      details: values.details
    })
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.contactDetailsApiId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_contact_us/contact_us`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(details));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), { 'Content-Type': 'application/json' });
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  // Customizable Area End
}